import { flowRight, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';

import { SECTIONS } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import {
  isLayoutNameTextOnImage,
  LAYOUT_NAME_SIDE_BY_SIDE,
  isLayoutNameMobile,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';

import PostListItemHeader from '../post-list-item-header';
import PostTitle from '../post-title';
import PostFooter from '../post-footer';
import Link from '../link/internal-link';
import UnpublishedChangesBadge from '../unpublished-changes-badge';
import ResponsiveContentExcerpt from '../responsive-content-excerpt';
import { getFeedColorClassName } from '../../services/layout-config';
import { HorizontalSeparatorForPostCard } from '../separator';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withFontClassName from '../../hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withPostFontSize from '../../hoc/with-post-font-size';
import withPermissions from '../../hoc/with-permissions';
import { getPostCoverImageSrc } from '../../selectors/post-selectors';
import { getPostActions } from '../../services/post-actions';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsMoreButtonEnabled } from '../../selectors/app-settings-selectors';

import styles from '../../styles/post-list-item.scss';

export const PostListItem = ({
  type,
  post,
  query,
  onLikeClick,
  itemConfig,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  hasUnpublishedChanges,
  isMetadataFooterVisible,
  isMetadataHeaderVisible,
  showMoreButton,
  showPostDescription,
  applyFeedDesign,
  getPostClassName,
  index,
  isMoreButtonEnabled,
  layoutSidesPadding,
  hideAuthorBadge,
}) => {
  const { lineCount, displayFooterIcons } = itemConfig;
  const postLink = `/${post.slug}`;
  const unpublishedBadge = hasUnpublishedChanges ? (
    <div className={styles.unpublishedBadge}>
      <UnpublishedChangesBadge className="blog-text-color" borderClassName={styles.unpublishedBadgeBorder} />
    </div>
  ) : null;

  const isMobileLayout = isLayoutNameMobile(type);
  const withoutFooter = !isMetadataFooterVisible;
  const withoutHeaderMetadata = !isMetadataHeaderVisible;
  const withoutDescription = !showPostDescription;
  const useResponsive = type === LAYOUT_NAME_SIDE_BY_SIDE;
  const containerClassName = classNames(
    styles.container,
    styles.proGallery,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    !applyFeedDesign && !isLayoutNameTextOnImage(type) && 'blog-hover-container',
    !isLayoutNameTextOnImage(type) && getPostClassName('hover-container'),
    getPostClassName('border-color', 'post-container', getFeedColorClassName(type, 'background-color')),
    isMobileLayout && index > 0 && styles.newPadding,
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    styles[type],
    !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
    withoutFooter && styles.withoutFooter,
    'post-list-item-wrapper',
    isLayoutNameTextOnImage(type) && getPostClassName('overlay-background-color'),
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
    isMobileLayout ? styles.newPadding : styles.boxShadow,
  );

  const linkClassName = classNames(
    styles.textWrapper,
    styles.proGallery,
    styles[type],
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    post.isPinned && styles.withIcons,
    getPostClassName('link-hashtag-hover-color'),
    showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleContainerClassName = classNames(
    styles.title,
    titleFontClassName,
    styles[type],
    withoutFooter && styles.withoutFooter,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    withoutDescription && withoutFooter && styles.withoutBottomMargin,
    withoutDescription && styles.withoutDescription,
    post.isPinned && styles.withIcons,
    showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleClassName = classNames(
    getPostClassName('title-font', getFeedColorClassName(type, 'title-color')),
    useResponsive && styles.responsiveTitle,
  );
  const contentClassName = classNames(
    styles.content,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassNameWithStyle,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    getPostClassName('description-style-font'),
  );
  const headerClassName = classNames(contentFontClassName, getPostClassName('description-font'));
  const footerClassName = classNames(
    getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
  );
  const titleStyle = isMobileLayout ? { fontSize: postTitleFontSize } : {};
  const contentStyle = !applyFeedDesign || isMobileLayout ? { fontSize: postDescriptionFontSize } : {};

  const containerStyle = {
    ...(getPostCoverImageSrc(post) && isLayoutNameTextOnImage(type) && { background: 'transparent' }),
    borderWidth: 0,
  };

  const getLayoutPaddingStyles = (layoutSidesPadding, layoutTextOnImage, isFooter = false) => {
    if (!layoutTextOnImage && isFooter) {
      return {};
    }

    return isNumber(layoutSidesPadding)
      ? {
          paddingLeft: layoutSidesPadding,
          paddingRight: layoutSidesPadding,
          ...(isFooter && {
            left: 0,
            right: 0,
          }),
        }
      : {};
  };

  return (
    <article className={containerClassName} tabIndex="0" style={containerStyle} data-hook="post-list-item">
      <div
        style={getLayoutPaddingStyles(layoutSidesPadding, isLayoutNameTextOnImage(type))}
        className={contentWrapperClassName}
      >
        <PostListItemHeader
          className={headerClassName}
          hasUnpublishedChanges={hasUnpublishedChanges}
          post={post}
          showMoreButton={isMoreButtonEnabled}
          hideAuthorBadge={hideAuthorBadge}
          showProfileImage={!isLayoutNameTextOnImage(type)}
          style={{ fontSize: postMetadataFontSize }}
          type={type}
          isProGallery={true}
        />
        <Link to={postLink} className={linkClassName} addHoverClasses={false}>
          {unpublishedBadge}
          <div
            style={{ fontSize: postTitleFontSize }}
            className={titleContainerClassName}
            data-hook="post-list-item__title"
          >
            <PostTitle
              type={type}
              title={post.title}
              query={query}
              style={titleStyle}
              className={titleClassName}
              {...(useResponsive ? {} : { lineCount: lineCount.title })}
            />
          </div>
          {showPostDescription && lineCount.description ? (
            <div style={contentStyle} className={contentClassName} data-hook="post-description">
              <ResponsiveContentExcerpt post={post} query={query} />
            </div>
          ) : null}
        </Link>
        {isMetadataFooterVisible && (
          <div
            style={getLayoutPaddingStyles(layoutSidesPadding, isLayoutNameTextOnImage(type), true)}
            className={classNames(styles.footer, styles[type], styles.withoutCover)}
          >
            {!isMobileLayout && (
              <HorizontalSeparatorForPostCard className={classNames(styles.separator, styles[type])} />
            )}
            <div style={{ fontSize: postMetadataFontSize }}>
              <PostFooter
                post={post}
                postLink={postLink}
                onLikeClick={onLikeClick}
                displayIcons={displayFooterIcons}
                className={footerClassName}
                commentClassName={classNames(getPostClassName('link-hashtag-hover-color'))}
              />
            </div>
          </div>
        )}
        {isMobileLayout && (
          <HorizontalSeparatorForPostCard
            className={classNames(
              styles.separator,
              styles.mobileSeparator,
              styles[type],
              isMetadataFooterVisible && styles.withMetadata,
            )}
          />
        )}
      </div>
    </article>
  );
};

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object,
  query: PropTypes.string,
  type: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  hasUnpublishedChanges: PropTypes.bool,
  isMetadataFooterVisible: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  applyFeedDesign: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  index: PropTypes.number,
  isMoreButtonEnabled: PropTypes.bool,
  layoutSidesPadding: PropTypes.number,
  hideAuthorBadge: PropTypes.bool,
  hideThreeDots: PropTypes.bool,
};

PostListItem.defaultProps = {
  isMetadataFooterVisible: true,
  isMetadataHeaderVisible: true,
};

const mapRuntimeToProps = (state, { post, canSee, hideThreeDots }) => {
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const showMoreButton = Boolean(postActions.length);

  return {
    showMoreButton,
    isMoreButtonEnabled: !hideThreeDots && getIsMoreButtonEnabled(state, showMoreButton),
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
  connect(mapRuntimeToProps),
)(PostListItem);
